import { Component, ViewChild, ChangeDetectorRef, AfterViewChecked, OnDestroy, ElementRef  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SexoffenderDataService } from '../../services/sexoffender-data.service';
import { ResultsMapComponent } from '../../components/results-map/results-map.component';
import { SearchboxComponent } from '../../components/searchbox/searchbox.component';
import { SearchresultsComponent } from '../../components/searchresults/searchresults.component';

import { APIReturn } from '../../models/apiReturn';
import { FilterComponent } from 'src/app/components/filter/filter.component';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})

export class ResultsComponent implements AfterViewChecked, OnDestroy {

  public apiReturn$: Observable<APIReturn[]>;
  public searchText = '';
  public searchType = '';
  public searchQuery  = {};

  public routeParams: object = {};
  public routeSub: Subscription;

  public browserDataTxt = 'List All Offenders';

  // results getting passed to cards
  public offendersResult: any[] = [];
  // if filtered, hold those here
  public filteredResults: any[] = [];
  // holds initial results if need to go back
  public initialResults: any[] = [];

  public actionKey = 'action';
  public subActionKey = 'subaction';
  public queryKey = 'q';

  public filterConfig = null;
  public filterOpen = false;
  public filterApplied = false;
  public filters = {};

  offenders: any = [];
  activeTab = 'list';

  public mapSelected: Subscription;

  @ViewChild('searchRes', {}) public searchRes: SearchresultsComponent;
  @ViewChild('searchboxComp', { static: true }) public searchboxComp: SearchboxComponent;
  @ViewChild('mapComp', { static: false }) mapComp: ResultsMapComponent;
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('resultCards') resultCards: ElementRef;
  @ViewChild('filterComp') filterComp: FilterComponent;

  constructor(
    public activatedRoute: ActivatedRoute,
    public location: Location,
    public offenderService: SexoffenderDataService,
    private cdr: ChangeDetectorRef,
    private snackBar: MatSnackBar
  ) {
    this.routeSub = this.activatedRoute.paramMap.subscribe(paramMap => {
      const paramKey = 'params';
      this.routeParams = paramMap[paramKey];
      this.runSearch();
    });
    this.mapSelected = this.offenderService.mapSelect.subscribe((data) => {
      this.selectFromMap(data);
    });
  }

  public ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  public ngOnDestroy(): void {
    this.routeSub.unsubscribe();
  }

  public searchTextEmitHandler(event): void {
    const searchText: string = encodeURIComponent(event.search);
    const action: string = event.action;

    let urlParts: string[];
    this.searchText = searchText;
    if (event.field) {
      const searchField = event.field;
      this.searchType = searchField;
      // this.searchQuery = searchText;
      urlParts = [action, searchField, searchText];
    } else {
      // this.searchQuery = searchText;
      urlParts = [action, searchText];
    }
    this.updateUrl(urlParts);
    this.routeParams = {
      action: 'search',
      subaction: event.field,
      q: searchText
    };
    if (this.searchRes) {
      this.searchRes.resetShowMore();
    }
    this.runSearch();
  }

  public runSearch(): void {
    if (this.routeParams[this.actionKey] === 'search') {
      // display loading search results snackbar while loading data
      this.snackBar.open('loading search results');
      if (this.routeParams[this.queryKey] === 'all') {
        const browseAll: boolean = typeof this.routeParams[this.subActionKey] === 'undefined';
        if (browseAll) {
          this.offenderService.getAllOffenders().subscribe(data => {
            this.setResults(data);
          });
        }
      } else {
        const searchParam: string = this.routeParams[this.queryKey];
        const subAction: string = this.routeParams[this.subActionKey];
        if (typeof searchParam !== 'undefined') {
          this.searchText = decodeURIComponent(searchParam);
          this.searchType = subAction;
          this.offenderService.getOffenders(subAction, searchParam).subscribe(data => {
            this.setResults(data);
          });
        }
      }
    } else {
      this.updateUrl(['search', 'all']);
    }
  }

  public setResults(data) {
    this.offendersResult = this.initialResults = this.filteredResults = data.offenders;
    this.searchQuery = data.query;
    this.filterConfig = this.offenderService.getFilter(this.offendersResult);
    this.filterApplied = false;
    // resets the filter
    this.filterComp.createFilters(this.filterConfig);
    // dismiss loading search results snackbar once resutls are dislayed
    this.snackBar.dismiss();
  }

  public selectFromMap(data) {
    // if applying selected, set result as selected
    if (data != null) {
      this.offendersResult = [];
      let tempOffenders = this.initialResults;
      tempOffenders.forEach(offender => {
        let addOffender = false;
        offender.addresses.forEach(add => {
          if (add.blockname == data){
            addOffender = true;
          }
        });
        if (addOffender) {
          this.offendersResult.push(offender); 
          this.resultCards.nativeElement.scrollTo({ top: 0, behavior: 'smooth'});
        }
      })
    } else {
      // clear selected back to filtered
      this.offendersResult = this.filteredResults;
    }
  }


  public browseAllDataHandler() {
    this.searchText = '';
    this.updateUrl(['search', 'all']);
    this.routeParams = {
      action: 'search',
      q: 'all'
    };
    this.runSearch();
  }

  public updateUrl(segs: string[]) {
    if ((!segs) || (segs.length === 0)) {
      return;
    }
    this.location.go('/' + segs.join('/'));
  }

  public toggleEvent(event): void {
    this.toggleViews(event);
  }

  public toggleViews(tab): void {
    // change tab
    this.activeTab = tab;
    // hack for resizing map on display block
    if (tab === 'map') {
      // set display to block before invalid size
      // ngClass works too slow
      document.getElementById('mapCont').classList.add('active');
      this.mapComp.invalidSize();
    }
  }

  public toggleFilter(event): void {
    this.filterOpen = !this.filterOpen;
  }

  public filterChangedHandler(filterObj) {
    // if changing filter
    if (filterObj[0] !== null) {
      this.filteredResults = this.offenderService.filterResults(filterObj[0]);
      this.offendersResult = this.filteredResults;
      this.filterConfig = this.offenderService.getFilter(this.offendersResult);
      this.filterApplied = true;
      this.filters = filterObj[0];
    }

    // keep filter view open
    this.filterOpen = filterObj[1];
  }
}
