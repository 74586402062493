<div class="search" role="search">
    <div class="mb-1 input-group">
      <app-dropdown #btnDDComp [(searchType)]="searchTypeModel" (selectedOptionEmmiter)='btnDropdownSelectedHandler($event)'></app-dropdown>
      <input [(ngModel)]="searchTextModel"
              (typeaheadOnSelect)="onSelect($event)"
              [typeahead]="completionSuggestions$"
              [typeaheadAsync]="true"
              [typeaheadOptionField]="typeheadOptionsDisplayProp"
              [typeaheadGroupField]="typeheadOptionsGroupProp"
              [typeaheadOptionsLimit]="autocompletionDropdownMax"
              [typeaheadSelectFirstItem]="selectFirstOption"
              [typeaheadIsFirstItemActive]="firstItemActive"
              class="form-control search__bar"
              placeholder="search..." aria-label="Search Box">
      <span>
        <button type="button" aria-label="Search Submit" class="btn search__btn" (click)="btnSearchClickHandler($event)">
          <i class="fa fa-search"></i>
        </button>
      </span>
    </div>
</div>
