<div class="filter">
  <div class="filter__header">Filter Results
    <i class="filter__header__icon fa fa-times float-right" (click)="toggleFilter()"></i>
    <ng-template #popTemplate class="filter__tooltip">
      <div class="filter__tooltip__section">
        Select fields you would like to filter by.
      </div>
      <div class="filter__tooltip__section">
        If multiple options are selected within a list, results matching any of the selections will be shown.
      </div>
      <div class="filter__tooltip__section">
        If options in multiple lists are selected, only results that match at least one option in each list will be shown.
      </div>
    </ng-template>
    <div type="button" class="float-right" triggers="click" container="body" [adaptivePosition]="false"
      [tooltip]="popTemplate" placement="left"><i class="fa fa-info filter__header__info "></i></div>
  </div>
  <div class="filter_container">
    <div class="filter__buttonRow">
      <button mat-flat-button color="primary" class="filter__buttonRow__button filter__buttonRow__button--themed" (click)="filter()">See Results</button>
      <button mat-stroked-button class="filter__buttonRow__button" (click)="clear()">Clear</button>
    </div>
    <div class="filter__section">
      <mat-accordion  multi>
        <mat-expansion-panel *ngFor="let field of _filters.fields" [expanded]="field.expanded">
          <mat-expansion-panel-header>
            <mat-panel-title>{{field.title}}</mat-panel-title>
            <mat-panel-description class="filter__applied">{{filtersApplied[field.name]}}</mat-panel-description>
          </mat-expansion-panel-header>
          <span class="filter__clearField" (click)="clearField(field.name)">Clear</span>
          <ul class="filter__choices">
            <li *ngFor="let choiceObj of field.choices">
              <mat-checkbox class="example-margin" (change)="updateComplete()" color="primary" [(ngModel)]="selectedData[field.name][choiceObj.choice]">{{choiceObj.choice}} ({{choiceObj.count}})</mat-checkbox>
            </li>
          </ul>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
