import { Component, Output, EventEmitter, Input, AfterViewInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent {

  @Input()
  set filters(val) {
    this.createFilters(val);
  }
  get filters() {
    return this._filters;
  }

  public _filters: any = {};
  public filtersApplied: any = {};
  public selectedData: any = {};

  @Output()
  public filterChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _snackBar: MatSnackBar) {}

  createFilters(val): void {
    if (val !== null) {
      this._filters = val;
      // create empty object for ngmodel to create into 
      // create empty object of arrays for showing applied
      this._filters["fields"].map(field => {
        this.selectedData[field.name] = {};
        this.filtersApplied[field.name] = [];
      });
    }

  }

  updateComplete(): void {
    // when field is updated - update the array list that populates the 
    // filters applied into the mat panel description area
    let obj = this.selectedData;
    let filterArray = {};
    // get each field object
    Object.keys(obj).map((key)=>{
      let fieldObj = obj[key];
      filterArray[key] = [];
      // map through true/false choices from checkbox setting
      Object.keys(fieldObj).map((choice)=>{
        if (fieldObj[choice]) {
          filterArray[key].push(choice);
        }
      });
    });
    // set filters applied to this new object of arrays
    this.filtersApplied = filterArray;
  }

  filter(): void {
    this._snackBar.open('Filter Applied!', 'Dismiss', {
      duration: 1000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom'
    });
    this.filterChanged.emit([this.filtersApplied, false]);
  }

  toggleFilter(): void {
    // use filter changed to toggle filter
    this.filterChanged.emit([null, false]);
  }

  clearField(fieldName): void {
    this.filtersApplied[fieldName] = [];
    // map through true/false choices from checkbox setting
    Object.keys(this.selectedData[fieldName]).map((choice)=>{
      this.selectedData[fieldName][choice] = false;
    });
    this.filterChanged.emit([this.filtersApplied, true]);
  }

  clear(): void {
    // clear out mapped filter objects
    this._filters["fields"].map(field => {
      Object.keys(this.selectedData[field.name]).map((choice)=>{
        this.selectedData[field.name][choice] = false;
      });
      this.filtersApplied[field.name] = [];
    });
    this._snackBar.open('Filter Cleared!', 'Dismiss', {
      duration: 1000
    });
    this.filterChanged.emit([this.filtersApplied, true]);
  }

}
