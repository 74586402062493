<div class="footer" [hidden]="!displayFooter">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-md mr-md-auto mt-3">
                <div class="footer__siteSection">
                    <div class="footer__siteName">
                        {{siteNameText | uppercase}}
                    </div>
                    <div class="footer__copyrightArea" *ngIf="copyrightText">
                        <p class="footer__copyrightText">Copyright © {{copyrightText}}</p>
                    </div>
                    <div class="footer__logo" *ngIf="logo">
                        <a href="{{logo.link}}" target="_blank">
                            <span class="footer__logo__text">Powered by</span><img class="footer__logo__image" src="{{logo.image}}" alt="{{logo.altText}}"></a>
                    </div>
                </div>
            </div>
            <div *ngFor="let column of footerColumns" class="col-12 col-md-3 mt-3">
                <span class="footer__columnHeader">{{column.header}}</span>
                <ul class="footer__list">
                    <li *ngFor="let link of column.links">
                        <div *ngIf="link.routerLink; then routerBlock else linkBlock"></div>
                        <ng-template #routerBlock><a [routerLink]="link.routerLink" class="footer__list__link">{{link.title}}</a></ng-template>
                        <ng-template #linkBlock><a target="{{link.target}}" href="{{link.link}}"class="footer__list__link">{{link.title}}</a></ng-template>
                        <hr class="footer__divider" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
