import { Component, OnInit, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import * as L from 'leaflet';
import * as esri from 'esri-leaflet';
import 'leaflet-extra-markers';

import { SexoffenderDataService } from '../../services/sexoffender-data.service';
import mapConfig from '../../../configs/mapConfig.json';
@Component({
  selector: 'app-results-map',
  templateUrl: './results-map.component.html',
  styleUrls: ['./results-map.component.scss']
})
export class ResultsMapComponent implements OnInit, AfterViewInit, OnDestroy {
  public map: L.Map;
  public boundaryLayer: L.GeoJSON = new L.GeoJSON();
  public featureLayer: L.GeoJSON = new L.GeoJSON();
  public selectedLayer: L.GeoJSON = new L.GeoJSON();

  public featureArray;
  public selectedFeatures = false;
  public shownFeaturesSub: Subscription;
  public selectedObjSub: Subscription;
  public resultSubscription: Subscription;

  // boundary polygon style
  public boundaryStyle = {
      color: '#ff7800',
      weight: 3,
      opacity: 0.65
  };

  public defaultIcon = L.ExtraMarkers.icon({
    markerColor: 'blue',
    shape: 'circle'
  });

  public selectedMarker = L.ExtraMarkers.icon({
    markerColor: 'orange',
    prefix: 'fa',
    icon: 'fa-user',
    shape: 'circle'
  });

  public centerPin = L.ExtraMarkers.icon({
    icon: 'fa-star',
    markerColor: 'red',
    shape: 'square',
    prefix: 'fa'
  });

  public greyIcon = L.icon({
    iconUrl: 'assets/map/grey_marker.png',
    shadowUrl: 'assets/map/markers_shadow.png',
    iconSize: [ 35, 45 ],
    iconAnchor: [ 17, 42 ],
    popupAnchor: [ 1, -32 ],
    shadowAnchor: [ 10, 12 ],
    shadowSize: [ 36, 16 ]
  });

  // default basemap and set bounds so that it doesn't look for tiles that aren't there
  public dcBasemapLayer = mapConfig.basemap;
  public dcBasemapLayerBounds: L.LatLngBounds =
    L.latLngBounds(L.latLng(mapConfig.layerBounds.xmin, mapConfig.layerBounds.ymin),
      L.latLng(mapConfig.layerBounds.xmax, mapConfig.layerBounds.ymax));
  // center of map
  public centerPt: L.LatLngExpression = [mapConfig.centerPoint.lat, mapConfig.centerPoint.long];
  // zoom of map
  public zoomLevel = mapConfig.zoomLevel;

  constructor(public offenderService: SexoffenderDataService) { }

  public ngOnInit(): void {
    // subscribe to changes in result subscription on map object
    this.resultSubscription = this.offenderService.mapParameters.subscribe((data) =>
      this.updateMap(data)
    );

    this.shownFeaturesSub = this.offenderService.showingFeatures$.subscribe(features => {
      this.populateFeatures(features);
    });

    this.selectedObjSub = this.offenderService.selectedOffender$.subscribe(feature => {
      this.selectFeature(feature);
    });

  }

  public ngAfterViewInit(): void {
    // initialize the map
    this.initMap();
  }

  public initMap(): void {
    // keep map within 10% of a buffer around DC
    const maxBnds = this.dcBasemapLayerBounds.pad(.1);
    // create a map and add the DC basemap
    this.map = L.map('map', {
      maxBounds: maxBnds,
      maxBoundsViscosity: 1.0,
      minZoom: 11
    });
    this.map.on('load', () => {
      this.invalidSize();
    });

    // create selected layer if needed
    this.selectedLayer = L.geoJSON(null, {}).addTo(this.map);

    this.map.setView(this.centerPt, this.zoomLevel);

    esri.tiledMapLayer({
      bounds: this.dcBasemapLayerBounds,
      url: this.dcBasemapLayer
    }).addTo(this.map);
  }

  public updateMap(mapParams): void {
    // clear layers if already populated
    this.clearLayers();

    // if zone populate zone area else create buffer circle
    if (mapParams.type === 'zone') {
      this.populateZoneAndFeatures(mapParams.data);
    } else if (mapParams.type === 'buffer') {
      this.drawBufferAndFeatures(mapParams.data);
    }
  }

  public populateFeatures(geoFeatures): void {
    this.clearSelected();
    // populate feature layer using geojson from service
    if (this.featureLayer) {
      this.featureLayer.clearLayers();
      this.featureArray = [];
    }

    this.featureLayer = L.geoJSON(geoFeatures, {
      // add custom marker to points
      pointToLayer: (feature, latlng) => {
        return L.marker(latlng, {
          icon: this.defaultIcon
        });
      },
      onEachFeature: (feature, layerInfo) => {
        // on click of marker - create select item and select - and pass up to service
        layerInfo.on('click', (e, self = this) => {
          const address = e.target.feature.properties.address;
          const searchObj = {
            sexoffendercode: null,
            address: address
          };
          self.selectFeature(searchObj);
          this.offenderService.selectOnMap(address);
        });
        this.featureArray.push(layerInfo);
      }
    }).addTo(this.map);
    this.resetView();
  }

  public selectFeature(searchObj): void {
    this.selectedLayer.clearLayers();
    const boundsArray = [];
    // itterate over features and set either default icon or selected
    this.featureArray.map(feat => {
      let mapSymbol = this.defaultIcon;
      feat.feature.properties.offenders.map(offender => {
        if (offender.sexoffendercode === searchObj.sexoffendercode) {
          if (searchObj.address !== null) {
            // if address provided - go only to that address
            if (feat.feature.properties.address === searchObj.address) {
              const ptLocation = feat.getLatLng();
              // add pin marker to the center
              L.marker(ptLocation, {
                icon: this.selectedMarker
              }).addTo(this.selectedLayer);
              boundsArray.push(feat.getLatLng());
              this.selectedFeatures = true;
            }
          } else {
            const ptLocation = feat.getLatLng();
            // add pin marker to the center
            L.marker(ptLocation, {
              icon: this.selectedMarker
            }).addTo(this.selectedLayer);
            boundsArray.push(feat.getLatLng());
            this.selectedFeatures = true;
          }
        }
        if (searchObj.sexoffendercode == null && feat.feature.properties.address === searchObj.address) {
          const ptLocation = feat.getLatLng();
          // add pin marker to the center
          L.marker(ptLocation, {
            icon: this.selectedMarker
          }).addTo(this.selectedLayer);
          boundsArray.push(feat.getLatLng());
          this.selectedFeatures = true;
        }
      });
    });
    // if bounds found, zoom map to it - else alert user no location found
    if (boundsArray.length > 0) {
      this.map.fitBounds(boundsArray);
    }
  }

  public populateZoneAndFeatures(data): void {
    if (data.boundaryFeature) {
      // if boundary feature, add it to map
      this.boundaryLayer = L.geoJSON(data.boundaryFeature, {
        style: this.boundaryStyle
      }).addTo(this.map);
      // fit boundary to extent
      this.map.fitBounds(this.boundaryLayer.getBounds());
    } else {
      this.map.fitBounds(this.dcBasemapLayerBounds);
    }
  }

  public drawBufferAndFeatures(data): void {
    const centerPt = L.latLng(data.centerpoint);
    this.boundaryLayer = L.geoJSON(data.boundaryFeature, {
      style: this.boundaryStyle
    }).addTo(this.map);
    // draw a circle boundary with radius given and same style as zones
    L.circle(centerPt, {
      radius: data.distance,
      weight: this.boundaryStyle.weight,
      color: this.boundaryStyle.color,
      opacity: this.boundaryStyle.opacity
    }).addTo(this.boundaryLayer);

    // add pin marker to the center
    L.marker(centerPt, {
      icon: this.centerPin
    }).addTo(this.boundaryLayer);

    // fit boundary to extent
    this.map.setView(centerPt, 15);
  }
  
  public clearSelected(): void {
    // clear selected layer
    if (this.selectedLayer) {
      this.selectedLayer.clearLayers();
    }
    // set to false and hide button and reset map zoom
    this.selectedFeatures = false;
    this.resetView();
    // pass that selection has been cleared
    this.offenderService.selectOnMap(null);
  }

  public resetView(): void {
    // if boundary layer has features, zoom to that otherwise zoom to points boundary
    if (this.boundaryLayer.getLayers().length > 0) {
      this.map.fitBounds(this.boundaryLayer.getBounds());
    } else if (this.featureLayer.getLayers().length > 0 && this.boundaryLayer.getLayers().length == 0) {
      this.map.fitBounds(this.featureLayer.getBounds());
    }
  }

  public clearLayers(): void {
    // clear boundary layer
    if (this.boundaryLayer) {
      this.boundaryLayer.clearLayers();
    }
    // clear feature layer
    if (this.featureLayer) {
      this.featureLayer.clearLayers();
    }
  }

  public invalidSize(): void {
    // function needed to reset map after display none
    this.map.invalidateSize();
  }

  public ngOnDestroy(): void {
    // remove the subscription
    this.resultSubscription.unsubscribe();
    this.shownFeaturesSub.unsubscribe();
    this.selectedObjSub.unsubscribe();
  }
}
