import { Component, Inject, Input, Output, AfterContentInit, EventEmitter } from '@angular/core';
import { SexoffenderDataService } from '../../services/sexoffender-data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-sex-offender-card',
  templateUrl: './sex-offender-card.component.html',
  styleUrls: ['./sex-offender-card.component.scss']
})
export class SexOffenderCardComponent implements AfterContentInit {

  @Input()
  public sexOffenderInfo: any = {};

  @Output()
  public toggleEmitter: EventEmitter<any> = new EventEmitter<any>();

  public mapableAddresses = false;
  public csosaHost = environment.csosaHost;

  constructor(public offenderService: SexoffenderDataService, private _snackBar: MatSnackBar) { }

  public ngAfterContentInit(): void {
    if (this.sexOffenderInfo) {
      this.sexOffenderInfo.addresses.map(address => {
        if(address.location.lat !== 0 && address.within) {
          this.mapableAddresses = true;
        }
      });
      this.sexOffenderInfo.home = this.sexOffenderInfo.addresses.filter(add => {
        if (add.type.toLowerCase() == "home") {
          return add;
        }
      });
      this.sexOffenderInfo.work = this.sexOffenderInfo.addresses.filter(add => {
        if (add.type.toLowerCase() == "work") {
          return add;
        }
      });
      this.sexOffenderInfo.school = this.sexOffenderInfo.addresses.filter(add => {
        if (add.type.toLowerCase() == "school") {
          return add;
        }
      });
    }
  }

  public clickCard(address): void {
    if (this.mapableAddresses) {
      if (address == null) {
        this.offenderService.selectFeature(this.sexOffenderInfo.sexoffendercode, address);
        this.toggleEmitter.emit('map');
      } else if (address.location.lat !== 0) {
        if (!address.within) {
          this._snackBar.open('Outside of selected filter; Clear filter to view on map!', 'Dismiss', {
            duration: 1000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom'
          });
        } else {
          // send offender ID and address if appropriate to service to handle response
          this.offenderService.selectFeature(this.sexOffenderInfo.sexoffendercode, address.blockname);
          this.toggleEmitter.emit('map');
        }
      }
    }
  }

}
