import { Component } from '@angular/core';
import sharedConfig from '../configs/sharedConfig.json';
import { isDevMode } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

// export class AppComponent implements OnInit {
export class AppComponent {
  title = 'SexOffender';

  header = sharedConfig.header;
  footer = sharedConfig.footer;

  constructor() {}

  public ngOnInit() {
    // remove log on prod build
    if (!isDevMode()) {
      console.log = function () {};
    }
  }

}
