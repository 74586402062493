import { Component, OnInit,  Input } from '@angular/core';

@Component({
  selector: 'bucket',
  templateUrl: './bucket.component.html',
  styleUrls: ['./bucket.component.scss']
})
export class BucketComponent implements OnInit {

  // bucket inputs
  @Input()
  public title: any;
  @Input()
  public items: any;


  constructor() {
    console.log('bucket created');
  }

  ngOnInit() {
  }

}