<app-banner></app-banner>
<div class="sexOffender">
  <!-- title, logo, searchbar, and "browse all" text -->
  <div class="title">
    <div class="title__wrapper">
      <img class="title__logo"
          src="assets/img/mpd_logo.png">
      <h1 class="title__text">
        Sex Offender Registry
        <span class="title__subtitle">
          </span>
      </h1>
    </div>
  </div> 
  <div class="sexOffender__container">
    <div class="sexOffender__content row mx-0">
      <div class="col12 sexOffender__searchbox">
        <app-searchbox #searchboxComp [searchTextModel]="searchText"
                  (searchTextEmitter)="searchTextEmitHandler($event) "
                  class="px-0 col-12 col-sm-9 col-md-7 mx-0 mx-sm-auto my-0">
        </app-searchbox>
      </div> 
      <div class="row col-12 sexOffender__browseAll">
        <div class="mx-auto my-2 text-secondary">
          <p class="sexOffender__browseAll__link"
            (click)="browseAllDataHandler()">
            {{browserDataTxt}}
          </p>
        </div>
      </div>
      <div class="row-fluid col-md-10 offset-md-1">
          <div *ngFor="let block of homeLinks" class="homeBuckets">
              <bucket [items]="block.items" [title]="block.title"></bucket>
          </div>
      </div>
    </div>
  </div>
</div>

