<mat-sidenav-container class="results" [hasBackdrop]=true (backdropClick)="sidenav.close()">
    <mat-sidenav #sidenav class="results__sidenav" [(opened)]="filterOpen" [fixedInViewport]=true [fixedTopGap]="74"
        position="start" mode="over">
        <app-filter #filterComp [filters]="filterConfig" (filterChanged)="filterChangedHandler($event)"></app-filter>
    </mat-sidenav>
    <mat-sidenav-content [ngClass]="{'noscroll':filterOpen===true}">
        <div class="results__nav">
            <div class="results__nav__item">
                <div [ngClass]="{ 'active':activeTab==='list'}" (click)="toggleViews('list')">List</div>
            </div>
            <div class="results__nav__item">
                <div [ngClass]="{ 'active':activeTab==='map'}" (click)="toggleViews('map')">Map</div>
            </div>
        </div>
        <div class="results__content">
            <div class="results__content__pane" [ngClass]="{ 'active':activeTab==='list'}">
                <div #resultCards class="results__content__pane__cards">
                    <div class="results__searchbox">
                        <app-searchbox #searchboxComp [searchTextModel]="searchText" [searchTypeModel]="searchType"
                            (searchTextEmitter)="searchTextEmitHandler($event)"
                            class="px-0 col-12 col-sm-9 col-md-7 mx-0 mx-sm-auto my-0">
                        </app-searchbox>
                    </div>
                    <div class="row col-12 results__browseAll">
                        <div class="mx-auto my-2 text-secondary">
                            <p class="results__browseAll__link" (click)="browseAllDataHandler()">{{browserDataTxt}}
                            </p>
                        </div>
                    </div>
                    <app-searchresults #searchRes 
                        [searchResults]="offendersResult" 
                        [searchQuery]="searchQuery"
                        (toggleFilter)="toggleFilter($event)"
                        (toggleEmitter)="toggleEvent($event)"></app-searchresults>
                </div>
            </div>
            <div class="results__content__pane" id="mapCont" [ngClass]="{ 'active':activeTab==='map'}">
                <div class="results__content__pane__map">
                    <div class="results__content__pane__map__container">
                        <app-results-map #mapComp (toggleEmitter)="toggleEvent($event)"></app-results-map>
                    </div>
                </div>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>