<div class="address" [ngClass]="!address.within ? 'addressDisabled': ''">
    <div class="address__mapIcon" *ngIf="address.location.lat != 0">
        <i class="fa fa-map-marker" [ngClass]="{'address__mapIcon__woDistance': address.distance == null}"></i>
        <p *ngIf="address.distance != null">({{distanceFixed(address.distance)}})</p>
    </div>
    <div [ngClass]="(address.location.lat != 0) ? 'address__link' : 'address__nolink'">
        <span>{{dcAddressToTitleCase(address.blockname)}}
            <span *ngIf="address.city != null">
                <br/>{{address.city}}, {{address.state}}, {{address.zipcode}}
            </span>
        </span>
    </div>
</div>