import { Component, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import cfg from '../../../configs/btndropdownConfig.json';

@Component({
  selector: 'app-dropdown',
  templateUrl: './btn-dropdown.component.html',
  styleUrls: ['./btn-dropdown.component.scss'],
})
export class BtnDropdownComponent implements AfterViewInit {

  @Output()
  public selectedOptionEmmiter: EventEmitter<any> = new EventEmitter<any>();

  public options: any;
  public isRendered: boolean;
  public caption: string;
  public disabled: boolean;
  public selected: any = {};

  @Input() public searchType = '';

  constructor() {
    this.setConfig(cfg);
  }
  public ngAfterViewInit() {
    // if value passed in from URL route, check against options and set if found
    if (this.searchType !== '') {
      const selectedOptions = this.options.filter(option => option.searchType.split(':').includes(this.searchType));
      if (selectedOptions.length > 0) {
        this.selectHandler(selectedOptions[0]);
      }
    }
  }

  public selectHandler(option): void {
    this.caption = option.label;
    this.selected = option;
    this.selectedOptionEmmiter.emit(option);
  }

  public setConfig(config): void {
    if (config) {
      this.options = config.options || [];
      this.isRendered = (this.options.length > 0);
      this.disabled = this.options.length <= 1;
      if (this.options.length > 0) {
        // if one more more set as selected
        this.selectHandler(this.options[0]);
      }
    } else {
      this.isRendered = false;
    }
  }
}
