<div class="container">
    <div class="col-10">
        <div class="disclaimer">

            <h2 class="disclaimer__title">Disclaimer</h2>

            <p>The Sex Offender Registration Act of 1999 authorizes the Court Services and Offender Supervision Agency (CSOSA) to establish and maintain the sex offender registry for the District of Columbia. The Law authorizes the Metropolitan Police Department to release sex offender information to the public.</p>

            <p>The Court Services and Offender Supervision Agency updates this information regularly in an effort to assure that the registry is complete and accurate. However, the Metropolitan Police Department and the District of Columbia cannot guarantee the accuracy of this information. Data contained in the registry may be primarily based upon information furnished by the offender (registrant). This information can change quickly. Offenders may have moved and failed to notify CSOSA as required by law.</p>

            <p>Persons who have been arrested or charged with a sex offense are not required to register unless the arrest or charge results in a conviction or a finding of not guilty by reason of insanity. Juveniles adjudicated (not charged or convicted as adults) of sexual abuse offenses are not required to register and will not appear on the registry. Please see the Sex Offender Registration for a list of those offenses requiring registration. For specific language pertaining to DC sexual abuse statutes, see Definition of Offenses.</p>

            <p>The information that is provided through this web site is an open record. It is your responsibility to make sure the records you access through this web site pertain to the person about whom you are seeking information. Extreme care should be exercised in using information obtained from this web site. Neither the Metropolitan Police Department nor the District of Columbia shall be responsible for any errors or omissions produced by secondary dissemination of this data.</p>

            <p>This information is not intended to create alarm or panic. Our intent is to inform our citizens and to enhance community safety and awareness. The Metropolitan Police Department has not considered or assessed the specific risk of reoffense for any individual registrant included in the registry. In addition, it has made no determination that any offender included in the registry is currently dangerous. Offenders are included in the registry solely by virtue of their conviction record.</p>

            <p>Unlawful use of this information to threaten, intimidate, harass, or injure a registered sex offender will not be tolerated and will be prosecuted to the full extent of the law.</p>

            <p>The online Sex Offender Registry database provides information on Class A and Class B sex offenders only. If you would like to see a complete list of all Class A, B, and C Offenders, you must go, in person, to one of the MPDC Registry Book locations.</p>

            <p>In accordance with enactment of the Sex Offender Registration Act of 1999, this information is being provided to the community. Funding for this program was provided through a grant from the Bureau of Justice Statistics, Office of Justice Programs, US Department of Justice. The title of this grant is the National Sex Offender Registry Project (98-NR-CX-K002).</p>

            <p>Address information is provided at the block level only; no specific addresses are provided. As a result, users should note that, at times, it may appear as if multiple offenders reside at a specific address. This is not always the case. You are cautioned that information provided on this website is information of record and may not reflect the current residence, place of employment, school, status, or other information concerning the registrant (offender). Please note that unless specifically indicated, these individuals are not wanted by the police at this time.</p>
                
            <p>If you have concerns about a sex offender in your neighborhood or sex offenders in general, have information on the whereabouts of a wanted sex offender, believe any information on this website is in error, or if you would like further information, please contact the Metropolitan Police Department, Sex Offender Registry Unit, at 300 Indiana Avenue, NW 20001, phone: (202) 727-4407, or email us at <a class="disclaimer__email" href="mailto:sexoffender.registry@dc.gov">sexoffender.registry@dc.gov</a>.</p>

        </div>

    </div>
</div>