<div class="navbar navbar-expand-lg header">
  <a href="{{logo.link}}" class="navbar-brand"><img class="header__logo" src="{{logo.image}}" alt="{{logo.altText}}"></a>
  <button *ngIf="links.length > 0"
          class="navbar-toggler header__toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-label="Toggle navigation"
          [attr.aria-expanded]="!isCollapsed"
          (click)="isCollapsed = !isCollapsed">
      <span class="navbar-toggler-icon header__toggler__icon"></span>
    </button>

  <div class="collapse navbar-collapse"
       id="navbarSupportedContent"
       [ngClass]="{'show': !isCollapsed}">
    <ul class="navbar-nav ml-auto">
        <li *ngFor="let link of links" class="nav-item ">
            <a href="{{link.link}}" target="_blank" class="nav-link header__link">{{link.title}}</a>
        </li>
    </ul>
  </div>
</div>