import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class HeaderComponent implements OnInit {

  public logo: any = {};
  public links: any = [];
  public isCollapsed = true;

  @Input()
  public configuration: any = {};

  constructor() {
  }

  ngOnInit() {
    if (this.configuration) {
      this.logo = this.configuration.logo;
      this.links = this.configuration.otherLinks;
    }
  }

}