import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-address-block',
  templateUrl: './address-block.component.html',
  styleUrls: ['./address-block.component.scss']
})
export class AddressBlockComponent {
  @Input()
  public address: any = {};

  constructor() { }

  public distanceFixed(dist: string) {
    return parseFloat(dist).toFixed(2);
  }

  public dcAddressToTitleCase(str: string) {
    const strArr: string[] = str.toLowerCase().split(/\s+/);
    if (strArr[1] === '-' && strArr[3].toUpperCase() === 'BLOCK') {
      if (parseInt(strArr[0], 10) < 100) { 
        strArr[0] = 'Unit';
      }
      strArr.splice(1, 2);
    }
    strArr.forEach((strPiece, index) => {
        switch (strPiece.toLowerCase()) {
            case 'se':
            case 'sw':
            case 'ne':
            case 'nw':
                // if suffix, convert to UPPER CASE
                strArr[index] = strPiece.toUpperCase();
                break;
            case 'of':
              // if of, make all lower case
              strArr[index] = strPiece.toLowerCase();
              break;
            default:
                // else camel case
                strArr[index] = strPiece.charAt(0).toUpperCase() + strPiece.slice(1);
        }
    });
    return strArr.join(' ');
  }
}
