import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
    // links to OCTO wide documents
    public footerColumns = [];
    public siteNameText = '';
    public copyrightText = null;
    public displayFooter = true;
    public logo = null;

    @Input()
    public configuration: any = {};

    constructor() { }

    ngOnInit() {
      if (this.configuration) {
        this.siteNameText = this.configuration.siteName;
        this.copyrightText = this.configuration.copyright;
        this.footerColumns = this.configuration.columns;
        this.logo = this.configuration.logo;
      }
    }
}
