import { Component, Input, AfterViewInit, ViewChild, Output, EventEmitter,
  NgZone, ElementRef, ChangeDetectorRef, OnDestroy, HostListener } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SexoffenderDataService } from '../../services/sexoffender-data.service';
import { noop, Subscription, Observable, Observer, of  } from 'rxjs';
import { map, tap, switchMap, debounceTime, distinctUntilChanged, catchError } from 'rxjs/operators';
import { BtnDropdownComponent } from '../btn-dropdown/btn-dropdown.component';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import searchBoxConfig from '../../../configs/searchboxConfig.json';

@Component({
  selector: 'app-searchbox',
  templateUrl: './searchbox.component.html',
  styleUrls: ['./searchbox.component.scss'],
  providers: [SexoffenderDataService],
})
export class SearchboxComponent implements AfterViewInit, OnDestroy {

  @ViewChild('txt') public eltxt: ElementRef;
  @ViewChild('btnDDComp') btnDropdownComp: BtnDropdownComponent;

  @Input()
  public searchTextModel: string;

  @Input()
  public searchTypeModel: string;

  @Output()
  public searchTextEmitter: EventEmitter<any> = new EventEmitter<any>();

  public completionOptions: string[];
  
  public search: string;
  public completionSuggestions$: Observable<string[]>;
  public errorMessage: string;
  public selectedOption: any;
  public selectFirstOption: boolean = true;
  public firstItemActive: boolean = true;
  public autocompletionDropdownMax: number = 5; // set default
  public typeheadOptionsGroupProp = "";
  public typeheadOptionsDisplayProp = "";

  public message = '';
  public showAutoComplete = false;

  public resultSubscription: Subscription;
  public elementRef: ElementRef;
  public completionSuggests: any[];

  constructor(
    public ds: SexoffenderDataService,
    public cdr: ChangeDetectorRef,
  ) { }

  public ngAfterViewInit() {
    this.autocompletionDropdownMax = searchBoxConfig.autocompletionDropdownMax;
    this.completionSuggestions$ = new Observable((observer: Observer<string>) => {
      observer.next(this.searchTextModel);
    })
    .pipe(
      debounceTime(50),
      distinctUntilChanged(),
      switchMap((query: string) => {
        if (query) {
          return this.ds.getAutocomplete(this.getBtnDropdownSearchType(), query)
          .pipe(
            map((data) => {
              this.completionOptions = data || [];
              return data || [];
            }),
            tap(() => noop, err => {
              this.completionOptions = [];
              // in case of http error
              console.log(err);
            })
          );
        }
 
        return of([]);
      }),
      catchError (this.handleError)
    );
    this.cdr.detectChanges();
  }

  public onSelect(event: TypeaheadMatch): void {
    this.selectedOption = event.item;
    if (this.selectedOption.type){
      this.searchTextModel = this.selectedOption.suggest;
    }
    this.doTextSearch(this.searchTextModel, this.getQueryField());
  }

  // get sex offender search field, logic too complex to move to config
  public getQueryField(): string {
    let queryField = "";
    // address search
    if (this.getBtnDropdownSearchType() == "address") {
      queryField = "address";
    }  else { // name/nickname search
      if ((this.selectedOption) && (this.selectedOption.type == "Nickname")) {
        queryField = "nickname";
      } else { //default name query field
        queryField = "fullname";
      }
    }
    return queryField;
  }

  public btnSearchClickHandler(event): void {
    if (this.completionOptions && this.completionOptions.length > 0) {
      this.selectedOption = this.completionOptions[0]; 
      if (this.selectedOption.type){
        this.searchTextModel = this.selectedOption.suggest;
      }
    }
    this.doTextSearch(this.searchTextModel, this.getQueryField());
  }

  public doTextSearch(searchText: string, fieldName: string = null) {
    if (!searchText) {
      return;
    }
    let resultAction = 'search';
    if (fieldName === null) {
      resultAction = 'search';
      this.ds.getAllOffenders();
    } else {
      this.ds.getOffenders(fieldName, searchText);
    }
    this.searchTextEmitter.emit({ action: resultAction, search: searchText, field: fieldName });
  }

  @HostListener('document:keyup', ['$event'])
  public onDocumentEnterKeyUp(event) {
    const keyCode: number = event.which || event.keyCode;
    if ((keyCode === 13)) {
        if (this.completionOptions.length > 0) {
          this.selectedOption = this.completionOptions[0];
          this.searchTextModel = (this.selectedOption.type) ? this.selectedOption.suggest : this.selectedOption;    
        }
      if (this.searchTextModel) {
        this.doTextSearch(this.searchTextModel , this.getQueryField());
      }
    }
  }

  public handleError(err): any {
    console.error('ERROR:' + err.message);
  }

  public ngOnDestroy(): void {
    // unsubscribe to ensure no memory leaks
    if (this.resultSubscription) {
      this.resultSubscription.unsubscribe();
    }
  }

  public getBtnDropdownSearchType(): string {
    return this.btnDropdownComp.selected.searchType;
  }

  public btnDropdownSelectedHandler(option) {
    // reset autocompletion dropdown
    this.completionOptions  = [];
    // reset search text
    this.searchTextModel = '';
    if (option.searchType !== "address") {
      this.typeheadOptionsDisplayProp = "suggest";
      this.typeheadOptionsGroupProp = "type";
    } else {
      this.typeheadOptionsDisplayProp = "";
      this.typeheadOptionsGroupProp = "";
    }
  }
}
