import { Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { catchError, retry, map, flatMap } from 'rxjs/operators';
import { APIReturn } from '../models/apiReturn';
import { Offender } from '../models/offender';
import { environment } from '../../environments/environment';
import sharedConfig from '../../configs/sharedConfig.json';
import apiConfig from '../../configs/apiConfig.json';
import filterConfig from '../../configs/filterConfig.json';

@Injectable({
  providedIn: 'root'
})
export class SexoffenderDataService  {

  public apiURL = environment.apiHost + apiConfig.apiURL;
  public marURL = environment.marHost;
  public initialLimit = sharedConfig.features.initiallimit;
  public offenders: Offender[] = [];
  public uniqueValues: any = [];
  public mapParameters: Subject<any> = new Subject<any>();
  public autocompleteUrl = environment.apiHost + apiConfig.apiURL_Autocomplete;

  public showingFeatures: Subject<Offender[]> = new Subject<Offender[]>();
  public showingFeatures$ = this.showingFeatures.asObservable();

  public selectedOffender: Subject<any> = new Subject<any>();
  public selectedOffender$ = this.selectedOffender.asObservable();

  public mapSelect: Subject<any> = new Subject<any>();
  public mapSelect$ = this.mapSelect.asObservable();

  public filteredOffenders: Subject<any> = new Subject<any>();
  public filteredOffenders$ = this.filteredOffenders.asObservable();

  public filterValues: Subject<any> = new Subject<any>();
  public filterValues$ = this.filterValues.asObservable();

  constructor(private httpClient: HttpClient) { }

  public getAutocomplete(type, term) {
    if (type.toLowerCase() === 'address') {
      return this.getMarAddressAutocomplete(term);
    }
    const autocompleteURL = `${this.autocompleteUrl}`;
    let params = new HttpParams();
    params = params.append('prefix', term);
    return this.httpClient.get(autocompleteURL, {params})
      .pipe(
        map(res => {
          const dataKey = 'data';
          return res[dataKey];
        }),
        retry(1),
        catchError(this.errorHandler)
      );
  }

  public getMarAddressAutocomplete(term) {
    const autocompleteEndpoint: string = this.marURL + apiConfig.mar.autocompleteEndpoint;
    const apiKey: string = apiConfig.mar.apiKey;
    const dataObj: object = {
      'address': term
    }
    const marSearchUrl = `${autocompleteEndpoint}?apikey=${apiKey}`;
    return this.httpClient.post<string[]>(marSearchUrl, dataObj)
    .pipe(
      map(res => {
        return res['Result'].filter(x => (x !== null)); // filter out null from mar autocompletion
      }),
      retry(1),
      catchError(this.errorHandler)
    );
  }


  public getAllOffenders(): Observable<any> {
    const searchURL = `${this.apiURL}`;
    return this.httpClient.get<APIReturn>(searchURL)
      .pipe(
        map(res => {
          const offenderList = this.processOffenders(res.data);
          // map params to export - offenders, zone type, and geojson for zone
          const mapParams = {
            type: 'points',
            data: {}
          };
          // set map parameters
          this.mapParameters.next(mapParams);
          this.setShowingOffenders(offenderList);
          return {
            offenders: offenderList,
            query: {
              type: 'all'
            }
          };
        }),
        retry(1),
        catchError(this.errorHandler)
      );
  }

  public getOffenders(type: string, searchTerm: string): Observable<any> {
    if (type.toLowerCase() === 'address') {
      return this.getOffendersDistance(searchTerm);
    } else {
      const term = `${type}=${searchTerm}`;
      const searchURL = `${this.apiURL}?${term}`;
      return this.httpClient.get<APIReturn>(searchURL)
        .pipe(
          map(res => {
            const offenderList = this.processOffenders(res.data);
            // map params to export - offenders, zone type, and geojson for zone
            const mapParams = {
              type: 'points',
              data: {}
            };
            // set map parameters
            this.mapParameters.next(mapParams);
            this.setShowingOffenders(offenderList);
            return {
              offenders: offenderList,
              query: {
                type: type,
                term: searchTerm
              }
            };
          }),
          retry(1),
          catchError(this.errorHandler)
        );
    }
  }

  public getOffendersDistance(address: string): Observable<any> {
    const apiKey: string = apiConfig.mar.apiKey;
    const marEndpoint: string = this.marURL + apiConfig.mar.endpoint;
    const searchMARURL = `${marEndpoint}?apiKey=${apiKey}`
    const dataObj: object = {
      'address': address
    }
    return this.httpClient.post(searchMARURL, dataObj)
      .pipe(
        flatMap(res => { 
          const results = res['Result'].addresses || res['Result'].blocks || res['Result'].intersections;
          const firstaddress = results[0].address || results[0].block || results[0].intersection;
          const longLat = firstaddress.geometry.coordinates[0] + ',' + firstaddress.geometry.coordinates[1];
          const dist = apiConfig.defaultDistance;
          const distInMeters = dist * 1609.344;  // conversion from mi to meter
          const distSearch = `${this.apiURL}?center=${longLat}&radius=${dist}`;
          return this.httpClient.get<APIReturn>(distSearch).pipe(
            map(res => {
              const offenderList = this.processOffenders(res.data);
              // map params to export - offenders, zone type, and geojson for zone
              const mapParams = {
                type: 'buffer',
                data: {
                  centerpoint: [firstaddress.geometry.coordinates[1], firstaddress.geometry.coordinates[0]],
                  distance: distInMeters // set initally - distance in meters
                }
              };
              // set map parameters
              this.mapParameters.next(mapParams);
              this.setShowingOffenders(offenderList);
              return {
                offenders: offenderList,
                query: {
                  type: 'distance',
                  address: firstaddress.properties.FullAddress || firstaddress.properties.FullBlock || firstaddress.properties.FullIntersection,
                  distance: dist
                } 
              };
            }),
            retry(1),
            catchError(this.errorHandler)
          );
        }),
        retry(1),
        catchError(this.errorHandler)
      );
  }

  public processOffenders(offenderArray: Offender[]): Offender[] {
    offenderArray.map(obj => {
      obj.addresses = [];
      let fieldArray = ["homeaddresses", "workaddresses", "schooladdresses"];
      fieldArray.forEach(field => {
        obj[field].forEach(add => {
          let type = field.replace("addresses", "");
          type = type.charAt(0).toUpperCase() + type.substr(1).toLowerCase();
          add["type"] = type;
          add["within"] = true;
        });
        obj.addresses = obj.addresses.concat(obj[field]);
      });
    });
    this.offenders = offenderArray;
    return offenderArray;
  }

  public selectFeature(offenderID, addressString?) {
    const offenderObj = {
      sexoffendercode: offenderID,
      address: addressString || null
    };
    this.selectedOffender.next(offenderObj);
  }

  public selectOnMap(address) {
    this.mapSelect.next(address);
  }

  public setShowingOffenders(offenderArray: Offender[]): void {
    // handle changing to geojson format on service side
    // allows map to be more portable
    const allAddress: any = [];
    offenderArray.map((offender) => {
      offender["addresses"].forEach(address => {
        if (address.location.lon !== 0 && (address["within"])) {
          const currentPoints = allAddress.filter(pt => {
            if (pt.properties.address === address.blockname) {
              return pt;
            }
          });
          if (currentPoints.length > 0) {
            currentPoints[0].properties.offenders.push({
              sexoffendercode: offender.sexoffendercode,
              firstname: offender.firstname,
              lastname: offender.lastname,
              address_type: address.type,
            });
          } else {
            allAddress.push({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [address.location.lon, address.location.lat]
              },
              properties: {
                address: address.blockname,
                offenders: [{
                  sexoffendercode: offender.sexoffendercode,
                  firstname: offender.firstname,
                  lastname: offender.lastname,
                  address_type: address.type,
                }]
              }
            });
          }
        }
      });
    });
    this.showingFeatures.next(allAddress);
  }

  /* filter logic */
  public getFilter(data): any {
    filterConfig.fields.forEach(field => {
      let valArray = [];
      // map the fields to the data and get all values
      data.map(obj =>{
        if (field.name.indexOf("address.") > -1) {
          let fieldName = field.name.replace("address.", "");
          obj.addresses.map(add => {
            let values = eval("add." + fieldName);
            if (values != null) {
              valArray.push(values);
            }
            return values;
          });
        } else {
          let values = eval("obj." + field.name);
          if (Array.isArray(values)) {
            values.forEach(value => {
              valArray.push(value);
            });
          } else {
            valArray.push(values);
          }
          return values;
        }
      });
      // reduce array and set counts of occurances
      field["choices"] = Object.values(valArray.reduce((r,s) => {
        (!r[s])? r[s] = {choice: s, count: 1} : r[s]['count']+=1;
        return r;
      }, {}));
    });
    console.log(filterConfig);
    return filterConfig;
  }

  public filterResults(filterObj): Offender[] {
    let filteredOffenders: Offender[] = [];
    // to check for empty search
    let emptySearch = true;

    // for each field and choice array in filter object
    Object.keys(filterObj).map(key => {
      let fieldFiltered: Offender[];
      let filteredIDs = [];
      if (filterObj[key].length > 0) {
        // filtered by this field
        fieldFiltered = [];
        if (filteredOffenders.length === 0) {
          // if first one - allow filter to capture from all
          filteredOffenders = JSON.parse(JSON.stringify(this.offenders));
        }
        // for each choice selected
        emptySearch = false;
        let filtered = filteredOffenders.filter(offender => {
          // check if value
          let valueFound = false;
          if (key.indexOf("address.") > -1) {
            let offenderAddressPresent = false;
            offender["addresses"].forEach(add => {
              // evaluate for each address if present
              let checkingVal = eval("add." + key.replace("address.", ""));
              if (checkingVal != null) {
                offenderAddressPresent = filterObj[key].indexOf(checkingVal) > -1;
                // if present, note on address so can show in map
                if (offenderAddressPresent) {
                  valueFound = true;
                  add["within"] = true;
                } else {
                  // otherwise set as not in filter
                  add["within"] = false;
                }
              }
            });
          } else {
            filterObj[key].forEach(choice => {
              // evaluate field object
              let checkingVal = eval("offender." + key);
              valueFound = checkingVal.indexOf(choice) > -1;
            });
          }

          // if not already captured and index of choice is present add
          if (valueFound && filteredIDs.indexOf(offender.sexoffendercode) == -1) {
            filteredIDs.push(offender.sexoffendercode);
            return offender;
          }
        });
        // concat into array of results
        fieldFiltered = fieldFiltered.concat(filtered);
        filteredOffenders = fieldFiltered;
      }
    });
    // if no filter applied, return all
    if (emptySearch) {
      filteredOffenders = this.offenders;
    }

    this.setShowingOffenders(filteredOffenders);
    this.filterValues.next(filterObj);
    return filteredOffenders;
  }

  clearFilter() {
    this.filterValues.next({});
  }
  /* end filter */

  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
