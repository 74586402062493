<!--search results-->
<div class="searchResult">
  <div class="searchResult__sideContent">
    <nav class="navbar border navbar-light bg-light">
      <h1 *ngIf="searchResults && searchResults.length > 0" class="navbar-brand mb-0 h1">Results ({{searchResults.length}})</h1>       
      <span class="searchResult__filterButton" *ngIf="searchResults.length > 0" (click)='toggleFilterEvent($event)'>
        <i class="searchResult__icon fa fa-filter"></i>
        <span>Filters</span>
      </span>
    </nav>
    <div *ngIf="searchResults && searchResults.length == 0">
      <h2 class="searchResult__resultCount" [innerHtml]="queryText()"></h2>
    </div>
    <div *ngIf="searchResults && searchResults.length > 0">
      <h2 class="searchResult__resultCount" [innerHtml]="queryText()"></h2>
      <div class="row">
        <div class=" col-12 mb-3" *ngFor="let offender of searchResults | slice:0:limit">
          <app-sex-offender-card class="searchResults__card__multi" [sexOffenderInfo]="offender"
            (toggleEmitter)='toggleEvent($event)'></app-sex-offender-card>
        </div>
      </div>
      <!-- show more section -->
      <div class="row justify-content-center pt-3" [hidden]="searchResults.length < limit">
        <div class="col-12 mb-2 text-center">
          <div class="searchResult__showing">Showing {{limit}} of {{searchResults.length}} results</div>
          <div class="progress" style="height: 1px; width:100%">
            <div class="progress-bar" role="progressbar" [style.width]="getPercentage() + '%'" [attr.aria-valuenow]="limit"
                  [attr.aria-valuemin]="0" [attr.aria-valuemax]="searchResults.length"></div>
          </div>
          <div class="my-3">
            <div class="btn btn-primary search-btn" [hidden]="searchResults.length === limit" (click)="increaseLimit()">Show More</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
