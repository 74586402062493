<!-- bucket container-->
<div class="bucket mb-3">
  <div class="bucket__title">{{title}}</div>
  <div class="bucket__container">
    <div *ngFor="let item of items" class="bucket__item">
      <div class="bucket__item__contentWrapper">
        <div *ngIf="item.routerLink; then routerBlock else linkBlock"></div>
        <ng-template #routerBlock>
          <a [routerLink]="item.routerLink" class="row bucket__item__link">
            <div class="col-5 col-md-12">
              <div class="bucket__item__contentWrapper__imageWrapper">
                <img *ngIf="item.image" alt="{{item.alt}}" class="bucket__item__contentWrapper__imageWrapper__image"
                      src="{{item.image}}"/>
              </div>
            </div>
            <div class="col-7 col-md-12 bucket__item__text">
              <div class="bucket__item__text__title">{{item.title}}</div>
              <div class="bucket__item__text__description">{{item.text}}</div>
            </div>             
          </a>
        </ng-template>
        <ng-template #linkBlock>
          <a href="{{item.link}}" target="{{item.target}}" class="row bucket__item__link">
            <div class="col-5 col-md-12">
              <div class="bucket__item__contentWrapper__imageWrapper">
                <img *ngIf="item.image" alt="{{item.alt}}" class="bucket__item__contentWrapper__imageWrapper__image"
                      src="{{item.image}}"/>
              </div>
            </div>
            <div class="col-7 col-md-12 bucket__item__text">
              <div class="bucket__item__text__title">{{item.title}}</div>
              <div class="bucket__item__text__description">{{item.text}}</div>
            </div>
          </a>
        </ng-template>
      </div>
    </div>
  </div>
</div>