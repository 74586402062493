import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';

// third party references
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';

// pages
import { HomeComponent } from './pages/home';
import { ResultsComponent } from './pages/results';
import { DisclaimerComponent } from './pages/disclaimer/disclaimer.component';

// components
import { AppComponent } from './app.component';
import { SearchboxComponent } from './components/searchbox/searchbox.component';
import { SexOffenderCardComponent } from './components/sex-offender-card/sex-offender-card.component';
import { ResultsMapComponent } from './components/results-map/results-map.component';
import { SearchresultsComponent } from './components/searchresults/searchresults.component';
import { BtnDropdownComponent } from './components/btn-dropdown/btn-dropdown.component';
import { AddressBlockComponent } from './components/sex-offender-card/address-block/address-block.component';
import { BannerComponent } from './components/banner/banner.component';
import { FilterComponent } from './components/filter/filter.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { BucketComponent } from './components/bucket/bucket.component';

@NgModule({
  declarations: [
    AppComponent,
    // app components
    HomeComponent,
    ResultsComponent,
    SearchboxComponent,
    SexOffenderCardComponent,
    SearchresultsComponent,
    ResultsMapComponent,
    BtnDropdownComponent,
    DisclaimerComponent,
    AddressBlockComponent,
    BannerComponent,
    FilterComponent,
    BucketComponent,
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TypeaheadModule.forRoot(),
    TooltipModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    FontAwesomeModule,
    MatSidenavModule,
    MatDividerModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatButtonModule,
    MatCardModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
