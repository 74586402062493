import { Component, Input, EventEmitter, Output } from '@angular/core';
import sharedConfig from '../../../configs/sharedConfig.json';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-searchresults',
  templateUrl: './searchresults.component.html',
  styleUrls: ['./searchresults.component.scss']
})
export class SearchresultsComponent {

  @Input()
  public searchResults: any[] = [];

  @Input()
  public searchQuery: any = {};

  @Output()
  public toggleEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public toggleFilter: EventEmitter<any> = new EventEmitter<any>();

  public baseUrl: string;

  // set default limit
  public limit = 6;
  public initialLimit = sharedConfig.features.initiallimit;
  public additional = sharedConfig.features.additional;
  public showMoreItteration = 1;

  public selectedOffender = '';

  public selectedObjSub: Subscription;

  public constructor() { }

  public queryText() {
    // if no results found, let user know why
    let queryText = '';
    let count: any = this.searchResults.length;
    if (count == 0) {
      count = 'No';
    }
    if (this.searchQuery && this.searchQuery['type'] == 'distance') {
      queryText = count + ' results within ' + this.searchQuery['distance'] + ' miles of <i>' + this.searchQuery['address'] + '</i>';
    } else if (this.searchQuery && this.searchQuery['term']) {
      queryText = count + ' results for <i>' + this.searchQuery['term'] + '</i>';
    }
    return queryText;
  }

  public getPercentage() {
    return Math.round(this.limit / this.searchResults.length * 100);
  }

  public increaseLimit() {
    if (this.limit + (this.additional * this.showMoreItteration) < this.searchResults.length) {
      this.limit = this.limit + (this.additional * this.showMoreItteration);
      if (this.showMoreItteration < 5) {
        this.showMoreItteration += 1;
      }
    } else {
      this.limit = this.searchResults.length;
    }
  }

  public resetShowMore() {
    this.scrollToTop();
    this.limit = this.initialLimit;
    this.showMoreItteration = 1;
  }

  public toggleEvent(event) {
    // bubble up to parent
    this.toggleEmitter.emit(event);
  }

  public toggleFilterEvent(event) {
    // bubble up to parent
    this.toggleFilter.emit(event);
  }

  public scrollToTop() {
    try {
      window.scrollTo({ left: 0, top: 0 });
    } catch (e) {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  }

}
