import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ResultsComponent } from './pages/results/results.component';
import { DisclaimerComponent } from './pages/disclaimer/disclaimer.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'results', component: ResultsComponent },
  { path: 'disclaimer', component: DisclaimerComponent },
  { path: ':action/:subaction/:q', component: ResultsComponent, runGuardsAndResolvers: 'always' },
  { path: ':action/:q', component: ResultsComponent, runGuardsAndResolvers: 'always' },
  { path: ':action', component: ResultsComponent, runGuardsAndResolvers: 'always' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
