<div class="offenderCard card h-100">
    <div class="card-body">
        <div class="offenderCard__container">
            <div class="offenderCard__sideBlock mx-auto md:m-0">
                <div class="offenderCard__sideBlock__container">
                    <img *ngIf="sexOffenderInfo.pictureid" class="offenderCard__sideBlock__image" alt="{{sexOffenderInfo.fullname}} image" src="{{csosaHost}}/ViewImage.aspx?src={{sexOffenderInfo.pictureid}}"/>
                    <img *ngIf="!sexOffenderInfo.pictureid" class="offenderCard__sideBlock__image" alt="picture not available" src="assets/icons/blank-picture.png"/>
                    <a class="offenderCard__sideBlock__link" attr.aria-label="Link for more details on {{sexOffenderInfo.fullname}}"
                        href="{{csosaHost}}/sor/public/genDataSheet.asp?id={{sexOffenderInfo.sexoffendercode}}" target="_blank">More Details</a>
                </div>
            </div>
            <div class="offenderCard__addressBlock">
                <div class="offenderCard__name" [ngClass]="{offenderCard__link: mapableAddresses == true}" (click)="clickCard(null)">{{sexOffenderInfo.fullname}}</div>
                <div *ngIf="sexOffenderInfo.nickname" class="offenderCard__addressBlock__header">Nicknames:</div>
                <div class="offenderCard__addressBlock__item">{{sexOffenderInfo.nickname?.join(', ')}}</div>
                <div class="offenderCard__addressBlock__header">Addresses:</div>
                <div class="offenderCard__addressBlock__item">
                    <div class="offenderCard__addressBlock__item__type">HOME</div>
                    <div *ngFor="let address of sexOffenderInfo.home" (click)="clickCard(address)">
                        <app-address-block [address]="address"></app-address-block>
                    </div>
                </div>
                <div class="offenderCard__addressBlock__item" 
                    *ngIf="sexOffenderInfo.work.length > 0 && sexOffenderInfo.work.blockname !== null">
                    <div class="offenderCard__addressBlock__item__type">WORK</div>
                    <div *ngFor="let address of sexOffenderInfo.work" (click)="clickCard(address)">
                        <app-address-block [address]="address"></app-address-block>
                    </div>
                </div>
                <div class="offenderCard__addressBlock__item" *ngIf="sexOffenderInfo.school.length > 0">
                    <div class="offenderCard__addressBlock__item__type">SCHOOL</div>
                    <div *ngFor="let address of sexOffenderInfo.school" (click)="clickCard(address)">
                        <app-address-block [address]="address"></app-address-block>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
