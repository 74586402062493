import { Component, EventEmitter, Output } from '@angular/core';
import { trigger, state, style, animate, transition,} from '@angular/animations';
import bannerConfig from '../../../configs/bannerConfig.json';
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  animations: [
    trigger('flyInOut', [
      transition(':enter', [
        style({ transform: 'translateY(-200%)' }),
        animate('0.3s 1s ease-out')
      ]),
      transition(':leave', [
        animate('0.2s ease-in', style({ transform: 'translateY(-200%)' }))
      ])
    ])
  ]
})

export class BannerComponent {
  public isShown = true;

  @Output() remove = new EventEmitter();

  constructor() {
    this.isShown = bannerConfig.displayBanner;
  }

  toggle() {
    this.isShown = !this.isShown;
  }
}
