import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// config params for featured links
import homeConfig from '../../../configs/homeConfig.json';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public homeLinks: any = homeConfig.featured;

  public searchTextModel: any;
  public searchText: any = '';

  public browserDataTxt = 'List All Offenders';

  public constructor(
    public router: Router
  ) {}

  ngOnInit(): void {
  }

  public searchTextEmitHandler(event): void {
    const searchText: string = encodeURIComponent(event.search);
    const searchField = event.field;
    const action: string = event.action;
    let urlParts: string[];

    if (event.field) {
      urlParts = [action, searchField, searchText];
    } else {
      urlParts = [action, searchText];
    }
    console.log(event);
    this.redirectTo(urlParts);
  }

  public browseAllDataHandler() {
    this.redirectTo(['search/all']);
  }

  public redirectTo(segs: string[]) {
    if ((!segs) || (segs.length === 0)) {
      return;
    }
    this.router.navigate(['/' + segs.join('/')]);
  }
}
